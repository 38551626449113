import * as React from "react"
import Sidebar from "@components/vertical-sidebar/VerticalSidebar"
import ListRegions from "@components/regions/List"
import Seo from "@components/seo"
import "../../styles/index.scss"

const AdditionalParams = {
  endPointName: "regions.getAll"
}

const RegionListPage = (props) => (
  <>
    <Seo title="Regions List" />
    <div className="main-wrapper">
      <Sidebar menuActive="settings" />
      <ListRegions
        addBtnText="Region" 
        menu="Regions" 
        parentPath="regions" 
        pageType="List"
        additionalParams={AdditionalParams}/>
    </div>
  </>
)

export default RegionListPage;
